const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
    return image;
  });
document.addEventListener("DOMContentLoaded", () => {
  $("#account_platform_design_primary_color").change(function () {
    for (let i = 0; i < 5; i++) {
      $(`.template-p${i}`).css("--primary", this.value);
    }
  });
  $("#account_platform_design_background_color").change(function () {
    for (let i = 0; i < 5; i++) {
      $(`.template-p${i}`).css("--secondary", this.value);
    }
  });
  $("#account_platform_design_secondary_color").change(function () {
    $(".logo-container").css("background-color", this.value);
  });
  $("#account_platform_design_text_color").change(function () {
    $(".template-p0 .app-container.middle-picture.desktop p").css("color", this.value);
  });
  $("#account_platform_design_subscription_image").change(async function () {
    let image = await createImage(URL.createObjectURL(this.files[0]));
    for (let i = 1; i < 5; i++) {
      $(`.template-p${i}`).css(
        "--platform-subscription-image",
        `url(${image.src})`
      );
    }
  });
  $("#account_platform_design_subscription_image_mobile").change(
    async function () {
      let image = await createImage(URL.createObjectURL(this.files[0]));
      for (let i = 1; i < 5; i++) {
        $(`.template-p${i}`).css(
          "--platform-subscription-mobile",
          `url(${image.src})`
        );
      }
    }
  );
  $("#account_platform_design_once_image").change(async function () {
    let image = await createImage(URL.createObjectURL(this.files[0]));
    for (let i = 1; i < 5; i++) {
      $(`.template-p${i}`).css(
        "--platform-subscription-image",
        `url(${image.src})`
      );
    }
    $(".template-p0").css("--middle-image", `url(${image.src})`);
  });
  $("#account_platform_design_once_image_mobile").change(async function () {
    let image = await createImage(URL.createObjectURL(this.files[0]));
    for (let i = 1; i < 5; i++) {
      $(`.template-p${i}`).css(
        "--platform-subscription-mobile",
        `url(${image.src})`
      );
    }
  });
  $("#account_platform_design_logo_reference").change(async function () {
    let image = await createImage(URL.createObjectURL(this.files[0]));
    $("#logo-img-1")[0].src = image.src;
    $("#logo-img-2")[0].src = image.src;
    $("#logo-img-3")[0].src = image.src;
  });

  $("#account_platform_design_image_id_1").change(async function () {
    $(".template-p0").css(
      "--middle-image",
      `url('https://storage.googleapis.com/fundingplatform-files/basic/platform/background-image-1.png')`
    );
  });
  $("#account_platform_design_image_id_2").change(async function () {
    $(".template-p0").css(
      "--middle-image",
      `url('https://storage.googleapis.com/fundingplatform-files/basic/platform/background-image-2.png')`
    );
  });
  $("#account_platform_design_image_id_3").change(async function () {
    $(".template-p0").css(
      "--middle-image",
      `url('https://storage.googleapis.com/fundingplatform-files/basic/platform/background-image-3.png')`
    );
  });

  // Campaign design

  $("#campaign_campaign_design_primary_color").change(function () {
    for (let i = 1; i < 5; i++) {
      $(`.template-f${i}`).css("--primary", this.value);
      $(`.cause`).css("--primary", this.value);
    }
  });

  $("#campaign_campaign_design_secondary_color").change(function () {
    for (let i = 1; i < 5; i++) {
      $(`.template-f${i}`).css("--secondary", this.value);
    }
  });

  $("#campaign_campaign_design_image_reference").change(async function () {
    let image = await createImage(URL.createObjectURL(this.files[0]));
    for (let i = 1; i < 5; i++) {
      $(`.template-f${i}`).css("--colecta-image", `url(${image.src})`);
      $(`.cause-${i}`).css("--image", `url(${image.src})`);
      $(`.image-container`).css("--image", `url(${image.src})`);
    }
  });

  $("#campaign_campaign_design_image_mobile_reference").change(
    async function () {
      let image = await createImage(URL.createObjectURL(this.files[0]));
      for (let i = 1; i < 5; i++) {
        $(`.template-f${i}`).css("--colecta-image-mobile", `url(${image.src})`);
      }
    }
  );

  $("#campaign_campaign_design_logo_reference").change(async function () {
    let image = await createImage(URL.createObjectURL(this.files[0]));
    // Obtener el logo reference y cambiar todos los src de las imágenes
    for (let i = 1; i < 4; i++) {
      if ($(`#logo-img-${i}`)[0]) {
        $(`#logo-img-${i}`)[0].src = image.src;
      }
    }
    if ($("#logo")[0]) {
      $("#logo")[0].src = image.src;
    }
  });

  // Event Designs
  $("#event_event_design_primary_color").change(function () {
    $(`.event-1`).css("--primary-color", this.value);
  });

  $("#event_event_design_secondary_color").change(function () {
    $(`.event-1`).css("--secondary-color", this.value);
  });

  $("#event_event_design_image").change(async function () {
    let image = await createImage(URL.createObjectURL(this.files[0]));
    // Obtener el logo reference y cambiar todos los src de las imágenes
    $(`.event-1`).css("--platform-once-image", `url(${image.src})`);
  });

  $($("#platform-language-selector")).on("change", function (e) {
    window.location.search = `language=${this.value}`;
  });
  // $("#account_platform_design_subscription_text").change(async function(){
  //   $("#subscription-header-1")[0].innerText = this.value
  // });
  // $("#account_platform_design_subscription_secondary_text").change(async function(){
  //   $("#subscription-header-2")[0].innerText = this.value
  // });
});
