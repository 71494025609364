import "select2";
import "select2/dist/css/select2.css";
import "@fortawesome/fontawesome-free/js/all";
import "../../stylesheets/backoffice";

document.addEventListener("DOMContentLoaded", () => {
  function formatOption(option) {
    var $option = $(
      "<div><strong>" +
        option.text +
        '</strong></div><div style="font-style: italic;">' +
        option.title +
        "</div>"
    );
    return $option;
  }

  $(".donation_transaction_kinds").select2({
    placeholder: "Seleccione uno o varios tipos de pagos para filtrar",
    allowClear: true,
  });
  $(".donation_transaction_statuses").select2({
    placeholder: "Seleccione uno o varios estados para filtrar",
    allowClear: true,
  });
  $(".bills_search").select2({
    placeholder: "Seleccione uno o varios estados para filtrar",
    allowClear: true,
  });
  $(".donation_transaction_currencies").select2({
    placeholder: "Seleccione una o varias divisas para filtrar",
    allowClear: true,
  });
  $(".donation_transaction_foundations").select2({
    placeholder: "Seleccione una o varias fundaciones para filtrar",
    allowClear: true,
  });
  $(".user_types").select2({
    placeholder: "Seleccione uno o varios tipos de usuario para filtrar",
    allowClear: true,
  });
  $(".supervisors").select2({
    placeholder: "Por supervisor",
    allowClear: true,
    class: "form-control",
  });
  $(".street_users").select2({
    placeholder: "Por Captador",
    allowClear: true,
  });
  $(".money-box").select2({
    placeholder: "Ingrese identificador",
    allowClear: true,
  });
  $(".regions").select2({
    placeholder: "Por región",
    allowClear: true,
    class: "form-control",
  });
  $(".locations").select2({
    placeholder: "Seleccione uno o varios programas para filtrar",
    allowClear: true,
  });
  $(".foundations_selector").select2({
    placeholder: "Seleccione una fundación",
    allowClear: true,
  });
  $(".supervisors_selector").select2({
    placeholder: "Seleccione un supervisor",
    allowClear: true,
  });
  $(".providers_selector").select2({
    placeholder: "Seleccione un proveedor",
    allowClear: true,
  });
  $(".regions_selector").select2({
    placeholder: "Seleccione una región",
    allowClear: true,
  });
  $(".segment_selector").select2({
    placeholder: "Seleccione un segmento",
    allowClear: true,
  });
  $(".locations_selector").select2({
    placeholder: "Seleccione un programa",
    allowClear: true,
  });
  $(".db-mkt-selector").select2({
    placeholder: "Seleccione uno o varias audiencias",
    templateResult: formatOption,
    allowClear: true,
    class: "form-control",
  });
  $(".foundations").select2({
    placeholder: "Seleccione una o varias fundaciones para filtrar",
    allowClear: true,
  });
  $(".campaigns").select2({
    placeholder: "Seleccione una o varias campañas para filtrar",
    allowClear: true,
  });
  $(".greeting_card_kinds").select2({
    placeholder: "Tipos de tarjetas",
    allowClear: true,
  });
  $(".standard-select").select2({
    placeholder: "",
    allowClear: true,
  });
  $(".event-category").select2({
    placeholder: "Seleccione una o varias opciones",
    allowClear: true,
  });
  $(".choices-selection").select2({
    placeholder: "Seleccione una o varias opciones",
    allowClear: true,
  });
  $(".causes-selection").select2({
    placeholder: "Seleccione una o varias causas",
    allowClear: true,
    class: "form-control",
  });
  $(".fundraisings-selection").select2({
    placeholder: "Seleccione una o varias colectas",
    allowClear: true,
    class: "form-control",
  });
  $(".raffles-selection").select2({
    placeholder: "Seleccione una o varias rifas",
    allowClear: true,
    class: "form-control",
  });
  $(".events-selection").select2({
    placeholder: "Seleccione uno o varios eventos",
    allowClear: true,
    class: "form-control",
  });
  $(".money-box-status-selection").select2({
    placeholder: "Estado alcancía",
    allowClear: true,
    class: "form-control",
  });
  $(".verified-emails-statuses").select2({
    placeholder: "Estado correos",
    allowClear: true,
    class: "form-control",
  });
  $(".verified-emails-active").select2({
    placeholder: "Correo suscrito",
    allowClear: true,
    class: "form-control",
  });
  $(".recruiter-search-bar").select2({
    placeholder: "Nombre del captador",
    allowClear: true,
  });
  $(".products").select2({
    placeholder: "Productos",
    allowClear: true,
  });
  $(".dona_en_ley_certificate_kind").select2({
    placeholder: "Seleccione un tipo de certificado",
    allowClear: true,
    class: "form-control",
  });
  $(".private_product").select2({
    placeholder: "Producto privado",
    allowClear: true,
    class: "form-control",
  });
  $(".user-features-selection").select2({
    placeholder: "Seleccione a qué se dará acceso",
    allowClear: true,
    class: "form-control",
  });
});
