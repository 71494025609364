document.addEventListener("DOMContentLoaded", () => {

  // Función cuando se hace click en el notification btn
  /* 
  1. Se saca el puntito rojo de las notificaciones.
  2. Se hace un post a una ruta que haga que las últimas 5 notificaciones se marquen como leídas.
  */

  $('.notificationsBtn').click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    if ($("#notificationDropMenu").css('display') == 'block'){
      $("#notificationDropMenu").hide();
    } else {
      $("#notificationDropMenu").show();
      $("#notification_alert").hide();
      console.log('Haciendo el post a las notificaciones');
      $.ajax({
        type: 'POST',
        url: '/backoffice/notifications/set_as_read'
      })
      .done( function() {
        console.log('Marcar notificaciones como leídas!');
      });
    };
  });
});