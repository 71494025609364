// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "./backoffice/notifications.js";
import "./backoffice/preview_design.js";
import "./backoffice/backoffice_styles.js";
import "chartkick";
import { format } from "rut.js";
import {
  formatIntField,
  formatPhoneNumber,
  loadPhoneNumber,
} from "./backoffice/utils";
import "./backoffice/form_fields";
import { Fn, lightOrDark, updateImgPreview } from "./utils.js";
import { rutFormValidity } from "./accounts/utils.js";
import AutoNumeric from "autonumeric";
import QRCodeStyling from "qr-code-styling";

require("@rails/ujs").start();
// require("turbolinks").start()
require("channels");
require("bootstrap");
require("jquery");
require("jquery.easing");
require("@nathanvda/cocoon/cocoon");

document.addEventListener("DOMContentLoaded", () => {
  var recaptcha_site_key = window.recaptcha_site_key;
  loadPhoneNumber();
  // Backoffice Theme settings
  import("./theme/sb-admin-2");

  const footer_color = $(".donando-footer").attr("data-footer-color");
  if (footer_color) {
    const color_type = lightOrDark(footer_color);
    const footer_images = Array.from(
      document.getElementsByClassName("donando-footer-image")
    );
    const social_media_icons = $(".social-media-footer-icon");
    if (color_type == "light") {
      footer_images.map((footer) => {
        footer.src =
          "https://storage.googleapis.com/fundingplatform-files/assets/donando/desarrollado-donando.png";
      });
      social_media_icons.each((element, data) => {
        data.style.color = "#000000";
      });
    } else if (color_type == "dark") {
      footer_images.map((footer) => {
        footer.src =
          "https://storage.googleapis.com/fundingplatform-files/assets/donando/desarrollado-donando-negative.png";
      });
      social_media_icons.each((element, data) => {
        data.style.color = "#ffffff";
      });
    }
  }

  // Amount-Quantity toggle
  $("#amount-quantity-toggle").click(({ target }) => {
    var type = target.getAttribute("data-type");
    var active_color = $(".chart-box")[0].getAttribute("data-active-color");
    if (type === "amount") {
      $(".amount").css("position", "relative");
      $(".amount").css("top", "0");
      $(".amount").css("left", "0");
      $(".quantity").css("display", "none");
      $(".amount").css("display", "");
      $(".right-btn").css("color", "#f8f9fc");
      $(".right-btn").css("background-color", active_color);
      $(".right-btn").css("border-color", active_color);
      $(".left-btn").css("color", active_color);
      $(".left-btn").css("background-color", "#f8f9fc");
      $(".left-btn").css("border-color", active_color);
    } else if (type === "quantity") {
      $(".quantity").css("display", "");
      $(".amount").css("display", "none");
      $(".left-btn").css("color", "#f8f9fc");
      $(".left-btn").css("background-color", active_color);
      $(".left-btn").css("border-color", active_color);
      $(".right-btn").css("color", active_color);
      $(".right-btn").css("background-color", "#f8f9fc");
      $(".right-btn").css("border-color", active_color);
    }
  });

  var splides = document.querySelectorAll(".splide");
  if (splides.length) {
    for (var i = 0; i < splides.length; i++) {
      var splideElement = splides[i];

      var splide = new Splide(splideElement, {
        type: "slide",
        perPage: splideElement.getAttribute("data-per-page") || 4,
        rewind: true,
        gap: "10px",
        breakpoints: {
          1200: {
            perPage: 1,
            height: "fit-content",
          },
        },
      });
      splide.mount();
    }
  }

  // form validations
  (function () {
    "use strict";
    window.addEventListener(
      "load",
      function () {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName("basic-needs-validation");
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
          form.addEventListener(
            "submit",
            function (event) {
              formatPhoneNumber();
              formatIntField();
              $(".tax_id_input").trigger("blur");
              var invalid_inputs = $(form).find(".is-invalid").length > 0;
              if (invalid_inputs === true || form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                form.classList.remove("was-validated");
              } else {
                form.classList.add("was-validated");
              }
            },
            false
          );
        });
      },
      false
    );
  })();
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  // Confirmable modal - button delete
  $(document).on("click", 'a[id*="delete-"]', function (e) {
    e.preventDefault();
    e.stopPropagation();
    const action = $(this).attr("data-action");
    const target = $(this).attr("id").split("-")[1];
    const tID = $(this).attr("id").split("-")[3];
    $(".modal").modal("hide");
    $(`#delete-${target}-modal-${tID}`)
      .on("shown.bs.modal", function () {
        $(`#delete-${target}-modal-${tID} form`).attr("action", action);
      })
      .modal();
    $(`#delete-${target}-modal`)
      .on("shown.bs.modal", function () {
        $(`#delete-${target}-modal form`).attr("action", action);
      })
      .modal();
  });

  // Confirmable modal
  $(document).on("click", 'a[id*="confirm-"]', function (e) {
    $(".loading-send-email").hide();
    e.preventDefault();
    e.stopPropagation();
    const action = $(this).attr("data-action");
    const message = $(this).attr("data-message");
    const target = $(this).attr("id").split("-")[1];
    const tID = $(this).attr("id").split("-")[3];
    const modal_id = tID
      ? `#confirm-${target}-modal-${tID}`
      : `#confirm-${target}-modal`;
    $(".modal").modal("hide");
    $(modal_id)
      .on("shown.bs.modal", () => {
        $(`${modal_id} p`)[0].innerHTML = message;
        $(`${modal_id} form`).attr("action", action);
      })
      .modal();
  });

  function copy(copyId) {
    navigator.clipboard.writeText($("" + copyId).text()).then(() => {
      $(".alert").fadeIn(500, function () {
        $(".alert").fadeOut();
      });
    });
  }

  //copy-buttons
  $(".copy-button").click(function (event) {
    event.preventDefault();
    event.stopPropagation();
    const copy_id =
      "#" + $(this).attr("id").replace("-copy-button", "") + "-field";
    copy(copy_id);
  });

  //excel download
  $(".excel-btn").click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    var route = $(this).data("href");
    $("#excelModal").modal("show");
    $("#send-excel-email-btn").attr("href", route);
  });

  //refund
  $(".refund-button").click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    var transaction_uuid = $(this).data("uuid");
    $("#refund-confirmation-button").attr(
      "href",
      `/backoffice/payments/${transaction_uuid}/refund`
    );
    $("#refund-payment-modal").modal("show");
  });

  $(".tax_id_input").on("input", ({ target }) => {
    let val = format(target.value);
    target.value = val;
  });

  $(".tax_id_input").on("blur", ({ target }) => {
    const rutField = target;
    const rutValidity = rutField ? Fn.validaRut(rutField.value) : true;
    const formParent = $(rutField).closest("form");
    rutFormValidity(rutValidity, formParent, $(rutField));
  });

  //Tax id format

  $("#account_tax_id").on("input", ({ target }) => {
    let val = format(target.value);
    target.value = val;
  });

  $("#account_billing_information_tax_id").on("input", ({ target }) => {
    let val = format(target.value);
    target.value = val;
  });

  //Disable email button

  $(".send-email-button").click(function (e) {
    const form = e.target.form;
    if (e.target.tagName == "INPUT" && form) {
      if (form.checkValidity()) {
        e.target.classList.add("disabled");
      }
    } else if (e.target.tagName == "A") {
      e.target.style.pointerEvents = "none";
    } else {
      e.target.classList.add("disabled");
    }
    $(".loading-send-email").show();
  });

  //Sales contact

  $("#sales-contact-button, .sales-contact-button").click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    var feature = $(this).data("feature");
    if (feature) {
      $.ajax({
        type: "POST",
        url: "/backoffice/sales_contact/" + feature,
      }).done(function () {
        alert("¡Solicitud enviada! Te contactaremos a la brevedad.");
      });
    }
  });
  $(".login-btn-captcha").click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    let form;
    if (document.getElementById("new_user")) {
      form = $("#new_user");
    } else if (document.getElementById("new_supervisor")) {
      form = $("#new_supervisor");
    } else if (document.getElementById("new_street_user")) {
      form = $("#new_street_user");
    }
    grecaptcha.enterprise.ready(function () {
      grecaptcha.enterprise
        .execute(recaptcha_site_key, { action: "submit" })
        .then(function (token) {
          let captcha = $(`input[name="recaptcha_token"`);
          for (var i = 0; i < captcha.length; i++) {
            captcha[i].value = token;
          }
          form?.submit();
        });
    });
  });

  $(".user_feature_checkbox").change(function (e) {
    const module = this.name;
    if (this.checked) {
      $(`#access_type_${module}`).show();
      $(`#access_type_${module}`).prop("required", true);
    } else {
      $(`#access_type_${module}`).hide();
      $(`.${module}-selection`).hide();
      $(`#access_type_${module}`).prop("required", false);
      $(`#access_ids_${module}`).prop("required", false);
    }
  });
  $(".user_access_type").change(function (e) {
    const module = $(this).attr("data-module");
    if (this.value == "1") {
      $(`.${module}-selection`).show();
      $(`#access_ids_${module}`).prop("required", true);
    } else {
      $(`.${module}-selection`).hide();
      $(`#access_ids_${module}`).prop("required", false);
    }
  });

  $(".iti__country-list li").click(function () {
    this?.parentElement?.parentElement?.nextSibling?.setAttribute(
      "data-code",
      $(this).data("dial-code")
    );
    formatPhoneNumber();
  });

  $("#phone_number_hidden_input").change(function (e) {
    formatPhoneNumber();
  });

  $(".circle-icon-btn").click(function (e) {
    $(".circle-icon-btn").removeClass("active");
    $(this).addClass("active");
    let target = $(this).data("target");
    $(".circle-icon-container").hide();
    $(`#${target}`).show();
  });

  $(".username-button").click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    var username = `https://${$(this).data("username")}`;
    var qr_name = $(this).data("qr-name");
    var qr_account_name = $(this).data("qr-account");
    var qr_url = $(this).data("qr-url");
    var qr_link = `//${qr_account_name}/qr_code_download?name=${qr_name}&url=${qr_url}`;
    $("#username-field").html(username);
    $("#username-qr").attr("href", qr_link);
    $("#username-modal").modal("show");
  });

  $(document).on("keypress", "input[id^='multiple-emails']", function (e) {
    if (e.which === 13) {
      e.preventDefault();
      e.stopPropagation();
      var val = $(this).val();
      var id = $(this).attr("id").split("_")[2];
      $("#emails-list").append("<li>" + val + "</li>");
      $("#emails-list-input").val(
        $("#emails-list-input").val() + ("<" + val + ">")
      );
      $(this).val("");
    }
  });
  $(".email-field-delete").on("click", function (e) {
    let email = $(this).data("email");
    $("#emails-list-input")[0].value = $("#emails-list-input")[0].value.replace(
      `<${email}>`,
      ""
    );
    this.parentElement.remove();
  });
  // Confirmable modal
  $(".table-actions-dropdown").click(function (e) {
    $(".table-cells").map((x, y) => (y.style.zIndex = 0));
    $(this).parent()[0].style.zIndex = 1;
  });

  $("#user_user_type").change(function (e) {
    $(".user-access").hide();
    if (this.value == 0) {
      $(".user-access").show();
    }
  });

  $("#communicationDropdown").on("click", function (e) {
    if (this.ariaExpanded == "true") {
      $('<div class="communication-backdrop"></div>').appendTo(document.body);
    } else {
      $(".communication-backdrop").remove();
    }
  });
  $(document).on("click", ".communication-backdrop", function (e) {
    $(".communication-backdrop").remove();
  });

  $(".qr-download").on("click", function (e) {
    var image_url =
      this.dataset.imageUrl ||
      "https://storage.googleapis.com/fundingplatform-files/assets/donando/logo-donando-black.png";
    var url = this.dataset.url;
    if (!/^https?:\/\//i.test(url)) {
      url = "https://" + url;
    } else if (/^http:\/\//i.test(url)) {
      url = url.replace(/^http:\/\//i, "https://");
    }
    var name = this.dataset.name;
    var qrcode = new QRCodeStyling({
      text: `${url}`,
      width: 500,
      height: 500,
      colorDark: "#000000",
      colorLight: "#ffffff",
      data: url,
      image: image_url,
      type: "png",
      backgroundOptions: {
        color: "#e9ebee",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 10,
      },
    });
    qrcode.download({ name: name, extension: "png" });
  });

  new AutoNumeric.multiple(".int_field", {
    digitGroupSeparator: ".",
    decimalCharacter: ",",
    decimalPlaces: 0,
  });
  new AutoNumeric.multiple(".float_field", {
    digitGroupSeparator: ".",
    decimalCharacter: ",",
    decimalPlaces: 2,
  });

  $(".image-upload").on("change", function (event) {
    handleImageChange(event);
  });

  function handleImageChange(event) {
    var img_width = event.target.getAttribute("data-img-width");
    var img_height = event.target.getAttribute("data-img-height");
    var inputElement = event.target;

    if (img_height && img_width && inputElement.files[0]) {
      var img = new Image();
      img.onload = function () {
        var width = img.naturalWidth;
        var height = img.naturalHeight;
        if (width != img_width || height != img_height) {
          alert(
            `Por favor, ingresa una imagen con las medidas correspondientes.\nMedidas pedidas: ${img_height}px de alto y ${img_width}px de ancho.\nMedidas subidas: ${height}px de alto y ${width}px de ancho.`
          );

          var newInputElement = $(inputElement).clone();
          $(inputElement).replaceWith(newInputElement);

          newInputElement.on("change", function (event) {
            handleImageChange(event);
          });
        } else {
          updateImgPreview(event);
        }
      };
      img.src = window.URL.createObjectURL(inputElement.files[0]);
    } else {
      updateImgPreview(event);
    }
  }
});

import JQuery from "jquery";
window.$ = window.JQuery = JQuery;
