
$(document).ready(function() {
  $(document).on("keypress", "input[id^='choices-input_field_']", function(e) {
    if(e.which === 13) {
      e.preventDefault();
      e.stopPropagation();
      var val = $(this).val();
      var id = $(this).attr('id').split("_")[2];
      $("#options-list_"+id).append("<li>"+val+"</li>");
      $("#options-list-input_"+id).val($("#options-list-input_"+id).val()+("<"+val+">"));
      $(this).val("");
    }
  });
  $(".choise-field-delete").on('click', function(e) {
    let choice_field_id = $(this).data('field')
    let choice_name = $(this).data('choice')
    $(`#options-list-input_${choice_field_id}`)[0].value = $(`#options-list-input_${choice_field_id}`)[0].value.replace(`<${choice_name}>`,'');
    this.parentElement.remove()
  });
});
$(function() {
  $(document).on('change', '.kind', function(e) {
    var id = $(this).attr('id').split("_")[1];
    if ($(this).children("option:selected").val() == 'many_choices' || $(this).children("option:selected").val() == 'one_choice') {
      $(`#input-container-${id}`).css('visibility','unset');
    } else {
      $(`#input-container-${id}`).css('visibility','hidden');
    }
  });
});
$(function() {
  $(document).on('click', '.add_fields', function(e) {
    if ($(".kind").length > 0){
      let id = parseInt($(".kind")[document.getElementsByClassName('kind').length - 2].id.split("_")[1], 10) + 1
      $('#select-type_').attr('id', `select-type_${id}`)
      $('#choices-input_field_').attr('id', `choices-input_field_${id}`)
      $('#options-list-input_').attr('id', `options-list-input_${id}`)
      $('#options-list_').attr('id', `options-list_${id}`)
      $('#input-container-').attr('id', `input-container-${id}`)
    }
  });
});